import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { register } from 'redux/actions/user.actions';
import { openLoginModal } from 'redux/actions/modal.actions';

import Modal from 'components/commons/Modal';
import RegisterForm from 'components/forms/RegisterForm';

const RegisterContainer = (props) => {
  const {
    loading, onClose, openLoginModalAction, registerAction,
  } = props;

  return (
    <Modal loading={loading} onClose={onClose} title="Registrate">
      <RegisterForm
        loading={loading}
        openLoginModal={openLoginModalAction}
        onSubmit={registerAction}
      />
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
});

const mapDispatchToProps = (dispatch) => ({
  openLoginModalAction: bindActionCreators(openLoginModal, dispatch),
  registerAction: bindActionCreators(register, dispatch),
});

RegisterContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openLoginModalAction: PropTypes.func.isRequired,
  registerAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
