import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { productPropTypes } from 'helpers/propTypes';
import { itemQuantitySelector, itemsQuantitySelector } from 'redux/selectors/cart.selector';
import { openProductModal } from 'redux/actions/modal.actions';
import { updateCart } from 'redux/actions/cart.actions';

import Item from 'components/commons/Item';

const ItemContainer = (props) => {
  const {
    cartItem, item, openProductModalAction, updateCartAction, cartItemDescriptions
  } = props;

  const quantity = cartItem ? cartItem.quantity : 0;

  const onQuantityChange = (value, description) => {
    if (description) {
      if (value >= 0 && value <= description.stock) {
        updateCartAction(item.id, value, description.stock_description_id, item.message);
      }
    } else if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null, item.message);
    }
  };

  return (
    <Item
      item={item}
      quantity={quantity}
      cartItemDescriptions={cartItemDescriptions}
      onItemClick={() => openProductModalAction(item)}
      onQuantityChange={onQuantityChange}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),

});

const mapDispatchToProps = (dispatch) => ({
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

ItemContainer.defaultProps = {
  cartItem: null,
  cartItemDescriptions: null,
};

ItemContainer.propTypes = {
  cartItemDescriptions: PropTypes.array.isRequired,
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  item: productPropTypes.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
  updateCartAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemContainer);
