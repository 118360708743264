import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from 'components/main/Header/Logo';
import { userPropTypes } from 'helpers/propTypes';
import heartImage from './assets/hart.svg';
import facebookImage from './assets/facebook-contact.svg';
import whatsappImage from './assets/whatsapp-contact.svg';
import instagramImage from './assets/instagram-contact.svg';

import './TopNav.scss';

const TopNav = (props) => {
  const {
    logout, openLoginModal, openRegisterModal, user,
  } = props;

  const renderUserLoggedOutButtons = () => (
    <div className="col-md-4 top-nav-user-actions" data-testid="top-nav-user-actions">
      <span className="top-nav-item" onClick={openRegisterModal} role="presentation">
        Crea tu cuenta
      </span>
      <span className="top-nav-item" onClick={openLoginModal} role="presentation">
        Iniciar sesi&oacute;n
      </span>

      <span className="top-nav-item-favourite" role="presentation">
        <img src={heartImage} className="image-heart" alt="" />
        Favoritos (3)
      </span>
    </div>
  );

  const renderUserLoggedInButtons = () => (
    <div className="col-md-4 top-nav-user-actions" data-testid="top-nav-user-actions">
      <NavLink className="top-nav-item top-nav-border-right" to="/perfil">
        <i className="fa fa-user" aria-hidden="true" />
        {' '}
        Mi cuenta
      </NavLink>
      <span className="top-nav-item" role="presentation" onClick={logout}>
        Cerrar sesi&oacute;n
      </span>
      <span className="top-nav-item" role="presentation">
        Mis compras
      </span>
      <span className="top-nav-item-favourite" role="presentation">
        <img src={heartImage} className="image-heart" alt="" />
        Favoritos (3)
      </span>
    </div>
  );

  return (
    <div className="top-nav" data-testid="topnav">
      <div className="container fill-height">
        <div className="row top-nav-content fill-height">
          <div className="col-md-4 top-nav-info">

            <span className="top-nav-item"><img src={whatsappImage} className="image-social" alt="whatsapp" /></span>
            <span className="top-nav-item"><img src={facebookImage} className="image-social" alt="facebook" /></span>
            <span className="top-nav-item top-nav-border-right"><img src={instagramImage} className="image-social" alt="instagram" /></span>
            <span className="top-nav-item top-nav-border-right">1161250017</span>
            <a className="top-nav-item" href="mailto:ventas.craco@gmail.com">
              ventas.craco@gmail.com
            </a>
          </div>
          <div className="col-md-4 top-nav-info text-center">
            <Logo />
            <span className="top-nav-item-middle">Distribuidora Mayorista</span>
          </div>
          {!user && renderUserLoggedOutButtons()}
          {user && renderUserLoggedInButtons()}
        </div>
      </div>
    </div>
  );
};

TopNav.defaultProps = {
  user: null,
};

TopNav.propTypes = {
  logout: PropTypes.func.isRequired,
  openLoginModal: PropTypes.func.isRequired,
  openRegisterModal: PropTypes.func.isRequired,
  user: userPropTypes,
};

export default TopNav;
