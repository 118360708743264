import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';

import { productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import { sectionsArray } from 'views/Home/constants';

import Benefit from 'components/commons/Benefit';
import Loading from 'components/commons/Loading';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.scss';

const Home = (props) => {
  const {
    currentSection, items, loading, minimumPurchase, onSectionClick, renderItem, sliders,
  } = props;

  const renderSilder = () => (
    <div data-testid="slider">
      <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        swipeable={false}
      >
        {sliders.map(({ id, image, text }) => (
          <div className="main-slider" key={id}>
            <img className="main-slider-img" src={image} alt={text} />
          </div>
        ))}
      </Carousel>
    </div>
  );

  return (
    <div data-testid="home-page">
      {renderSilder()}
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section">
              <Benefit minimumPurchase={minimumPurchase} />
            </div>
            <div className="section">
              <div className="row align-items-center">
                <div className="col text-center">
                  <div className="new-arrivals-sorting">
                    <ul className="arrivals-grid-sorting clearfix button-group filters-button-group">
                      {sectionsArray.map((section) => (
                        <li
                          className={`grid-sorting-button button ${section.value === currentSection.value ? 'active' : ''}`}
                          data-testid={`section-${section.value}`}
                          key={section.value}
                          onClick={() => onSectionClick(section.value)}
                          role="presentation"
                        >
                          {section.label}
                        </li>
                      ))}
                    </ul>
                    <div className="line-home"/>
                  </div>
                </div>
              </div>
              <div className="row align-items-center product-slider-container" data-testid="carousel">
                {loading && <Loading />}
                {!loading && (
                  <>
                    {!items.length && <h4 className="text-center">No se encontraron productos</h4>}
                    {!!items.length && items.map((item) => renderItem(item))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  currentSection: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onSectionClick: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  sliders: slidersArrayPropTypes.isRequired,
};

export default Home;
