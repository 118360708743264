import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import { cartItemsArrayPropTypes, userPropTypes } from 'helpers/propTypes';
import { isAbleToPurchase } from 'helpers/cart';
import { CART_CHECKOUT_DELIVERY_REQUESTED } from 'redux/constants/cart.constants';
import { checkout, submitCoupon, deleteCoupon } from 'redux/actions/cart.actions';
import { cartSizeSelector, cartItemsSelector, cartSubtotalSelector } from 'redux/selectors/cart.selector';
import { getShippingText } from 'components/forms/PaymentForm/constants';

import Checkout from 'views/Checkout';
import DeliveryForm from 'components/forms/DeliveryForm';
import PaymentForm from 'components/forms/PaymentForm';

const DeliveryContainer = (props) => {
  const {
    cartItems, cartSize, checkoutAction, submitCouponAction, deliveryMethod, items, loading,
    minimumPurchase, subtotal, user, coupon, deleteCouponAction, resetCouponField,
  } = props;
  const [deliveryFormData, setDeliveryFormData] = useState(null);
  const [couponText, setCouponText] = useState(null);
  const [emailComplete, setEmailComplete] = useState(false);
  const [emailCoupon, setEmailCoupon] = useState(null);

  useEffect(() => {
    if (coupon) {
      deleteCouponAction();
    }
  }, []);
  const onSubmitDeliveryForm = (formData) => {
    if (isAbleToPurchase(subtotal, minimumPurchase)) {
      const data = { ...formData, coupon: coupon?.id };
      setDeliveryFormData(data);
    }
  };

  const onSubmitCoupon = () => {
    if (couponText && emailCoupon) {
      submitCouponAction(couponText, emailCoupon);
    }
  };

  const onDeleteCoupon = () => {
    deleteCouponAction();
    resetCouponField('');
    setCouponText('');
  };

  const validationEmailCoupon = (email) => {
    if (!email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailComplete(false);
      onDeleteCoupon();
    } else {
      setEmailComplete(true);
      setEmailCoupon(email);
    }
  };
  const renderDeliveryForm = () => (
    <DeliveryForm
      coupon={coupon}
      onSubmit={onSubmitDeliveryForm}
      user={user}
      onSubmitCoupon={coupon ? onDeleteCoupon : onSubmitCoupon}
      couponText={couponText}
      setCouponText={setCouponText}
      emailComplete={emailComplete}
      setEmailComplete={setEmailComplete}
      setEmailCoupon={setEmailCoupon}
      validationEmailCoupon={validationEmailCoupon}
    />
  );

  const onSubmitPaymentForm = (formData) => {
    if (isAbleToPurchase(subtotal, minimumPurchase)) {
      checkoutAction(
        CART_CHECKOUT_DELIVERY_REQUESTED,
        { ...deliveryFormData, ...formData },
        cartItems,
        user ? user.id : null,
      );
    }
  };

  const renderPaymentForm = () => (
    <PaymentForm
      deliveryMethod={deliveryMethod}
      onSubmit={onSubmitPaymentForm}
    />
  );

  return (
    <Checkout
      coupon={coupon}
      cartSize={cartSize}
      items={items}
      loading={loading}
      minimumPurchase={minimumPurchase}
      renderForm={deliveryFormData ? renderPaymentForm : renderDeliveryForm}
      shippingText={getShippingText(deliveryMethod, subtotal)}
      subtotal={subtotal}
      url="/envio-a-domicilio"
      urlText="Envío a domicilio"
    />
  );
};

const selector = formValueSelector('payment');
const mapStateToProps = (state) => ({
  coupon: state.cart.coupon,
  cartItems: state.cart.items,
  cartSize: cartSizeSelector(state),
  deliveryMethod: selector(state, 'delivery_method'),
  items: cartItemsSelector(state),
  loading: state.cart.loading,
  minimumPurchase: state.app.minimumPurchase,
  subtotal: cartSubtotalSelector(state),
  user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
  checkoutAction: bindActionCreators(checkout, dispatch),
  submitCouponAction: bindActionCreators(submitCoupon, dispatch),
  deleteCouponAction: bindActionCreators(deleteCoupon, dispatch),
  resetCouponField: (value) => dispatch(change('delivery', 'coupon', value)),
});

DeliveryContainer.defaultProps = {
  deliveryMethod: null,
  user: null,
  coupon: null,
};

DeliveryContainer.propTypes = {
  cartItems: cartItemsArrayPropTypes.isRequired,
  cartSize: PropTypes.number.isRequired,
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }).isRequired,
  submitCouponAction: PropTypes.func.isRequired,
  deleteCouponAction: PropTypes.func.isRequired,
  resetCouponField: PropTypes.func.isRequired,
  checkoutAction: PropTypes.func.isRequired,
  deliveryMethod: PropTypes.string,
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  user: userPropTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryContainer);
