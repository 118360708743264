import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import PrivateRoute from 'containers/PrivateRoute';
import HomeContainer from 'containers/HomeContainer';
import ProductsContainer from 'containers/ProductsContainer';
import OffersContainer from 'containers/OffersContainer';
import ProductSingleContainer from 'containers/ProductSingleContainer';
import SearchProductContainer from 'containers/SearchProductContainer';
import ContactContainer from 'containers/ContactContainer';
import CartContainer from 'containers/CartContainer';
import DeliveryContainer from 'containers/DeliveryContainer';
import PickupContainer from 'containers/PickupContainer';
import ProfileContainer from 'containers/ProfileContainer';
import PurchaseHistoryContainer from 'containers/PurchaseHistoryContainer';
import FrequentQuestions from 'views/FrequentQuestions';
import SuccessfulPurchase from 'views/SuccessfulPurchase';
import Error from 'views/Error';

const NavigationContainer = () => (
  <Switch>
    <Redirect exact from="/" to="/home" />
    <Route path="/home/:section?" component={HomeContainer} />
    <Route path="/productos/:category?" component={ProductsContainer} />
    <Route path="/ofertas/:category?" component={OffersContainer} />
    <Route path="/hot-sale/:category?" component={OffersContainer} />
    <Route path="/producto/:product" component={ProductSingleContainer} />
    <Route path="/busqueda/:search/:category?" component={SearchProductContainer} />
    <Route path="/contacto" component={ContactContainer} />
    <Route path="/carrito" component={CartContainer} />
    <Route path="/envio-a-domicilio" component={DeliveryContainer} />
    <Route path="/retiro-en-sucursal" component={PickupContainer} />
    <Route path="/preguntas-frecuentes" component={FrequentQuestions} />
    <Route path="/compra-exitosa" component={SuccessfulPurchase} />
    <PrivateRoute path="/perfil" component={ProfileContainer} />
    <PrivateRoute path="/compras" component={PurchaseHistoryContainer} />
    <Route component={Error} />
  </Switch>
);

export default NavigationContainer;
