import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { sendErrorNotification } from 'helpers/notifications';

import './QuantitySelector.scss';

const QuantitySelector = (props) => {
  const {
    limit, onQuantityChange, quantity, description,
  } = props;
  const inputRef = useRef(null);
  const [value, setValue] = useState(quantity);

  useEffect(() => {
    setValue(quantity);
    const handleKeyUp = (event) => {
      // 13: Enter key
      if (event.keyCode === 13) {
        inputRef.current.blur();
      }
    };
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
    // eslint-disable-next-line
  }, [quantity]);

  const onChange = (event) => {
    setValue(event.target.value);
  };

  const onSubmit = () => {
    if (!value || !/^[0-9]*$/i.test(value)) {
      setValue(quantity);
      return;
    }

    if (value > limit) {
      sendErrorNotification(`El producto no cuenta con más de ${limit} unidades para agregar al carrito`);
      setValue(limit);
      onQuantityChange(limit, description);
      return;
    }

    onQuantityChange(parseInt(value, 0), description);
  };

  return (
    <div className="quantity d-flex flex-row align-items-center" data-testid="quantity-selector">
      {!description ? (
        <>
          <span className="quantity-text">Cantidad:</span>
          <div className="quantity-selector">
            <span className="minus" role="presentation" onClick={() => onQuantityChange(quantity - 1, description)} data-testid="quantity-selector-minus">
              <i className="fa fa-minus" aria-hidden="true" />
            </span>
            <span>
              <input type="number" value={value} onChange={onChange} onBlur={onSubmit} ref={inputRef} max={limit} data-testid="quantity-selector-input" />
            </span>
            <span className="plus" role="presentation" onClick={() => onQuantityChange(quantity + 1, description)} data-testid="quantity-selector-plus">
              <i className="fa fa-plus" aria-hidden="true" />
            </span>
          </div>
        </>
      ) : (
        <div className="quantity-selector-description">
          <span>
            <input type="number" value={value} onChange={onChange} onBlur={onSubmit} ref={inputRef} max={limit} data-testid="quantity-selector-input" />
          </span>
        </div>
      )}
    </div>
  );
};

QuantitySelector.propTypes = {
  limit: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  // description: PropTypes.object.isRequired,
};

export default QuantitySelector;
