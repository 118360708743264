import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { categoriesArrayPropTypes, productsArrayPropTypes } from 'helpers/propTypes';

import Menu from 'components/main/Header/Menu';
import Logo from 'components/main/Header/Logo';
import Button from 'components/commons/Button';

import './Navbar.scss';

const Navbar = (props) => {
  const {
    cartSize, categories, closeMenu, isMenuActive, isUserLoggedIn, openMenu, openSearchModal, openSearch, closeSearch, isSearchActive,
    products, filterOptions, productSelected, handleInputChange, inputSelectProduct, onSubmit, // selectProduct,
  } = props;

  return (
    <div className="main-nav-container">
      <div className="navbar-mobile">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav className="navbar">
                <div className="navbar-content">
                  <div className="text-center">
                    <Logo />
                    <span className="top-nav-item-middle">Distribuidora Mayorista</span>
                  </div>
                  <Menu
                    categories={categories}
                    closeMenu={closeMenu}
                    isUserLoggedIn={isUserLoggedIn}
                    ulClassName="navbar-menu"
                  />
                  <ul className="navbar-user">
                    <li>
                      { !isSearchActive
                        ? (
                          <span className="icon-search-nav">
                            <i className="fa fa-search icon-orange" aria-hidden="true" onClick={openSearch} />
                          </span>
                        ) : (
                          <span className="icon-search-nav">
                            <i className="fa fa-search " aria-hidden="true" onClick={closeSearch} />
                          </span>
                        )}
                    </li>
                    <li>
                      { !isMenuActive
                        ? (
                          <div
                            className="hamburger-container"
                            role="presentation"
                            onClick={openMenu}
                          >
                            <i className="fa fa-bars" aria-hidden="true" />
                          </div>
                        )
                        : (
                          <div
                            className="hamburger-close"
                            role="presentation"
                            onClick={closeMenu}
                          >
                            <i className="fa fa-times" aria-hidden="true" />
                          </div>
                        )}

                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="navbar-categories" data-testid="navbar-categories">
        <div className="container">
          <div className="row">
            <ul>
              <li>
                <NavLink to="/home">
                  Home
                </NavLink>
              </li>
              <li className="categories-navbar">
                <NavLink to="/home">
                  Categorias
                </NavLink>
                <div className="parent">
                  {
                    categories.map((category) => (
                      <div className="child">
                        <Link to={`/productos/${category.code}`}>
                          {category.name}
                        </Link>
                      </div>
                    ))
                  }
                </div>
              </li>
              <li>
                <NavLink to="/productos">
                  Productos
                </NavLink>
              </li>
              <li>
                <div className="nabvar-searchContainer">
                  <div className="autocomplete">
                    <Autocomplete
                      fullWidth
                      disablePortal
                      id="combo-box-demo"
                      // classes="nabvar-search"
                      options={products}
                      // value={selectProduct}
                      filterOptions={filterOptions}
                     // inputValue={inputSelectProduct}
                      onChange={(event, product) => {
                        productSelected(product);
                      }}
                      onInputChange={handleInputChange}
                      // sx={{ width: 400 }}
                      getOptionLabel={(option) => (option ? option.name : '')}
                      renderInput={(params) => <TextField {...params} placeholder="¿Que producto estas buscando?" />}
                    />
                    <Button
                      buttonClass="nabvar-btn-search"
                      disabled={false}
                      onClick={onSubmit}
                      text={<i className="fa fa-search icon-orange" aria-hidden="true" />}
                      type="button"
                    />
                  </div>
                </div>
              </li>
              <li>
                <NavLink to="/preguntas-frecuentes">
                  Preguntas frecuentes
                </NavLink>
              </li>
              <li>
                <NavLink to="/contacto">
                  Contacto
                </NavLink>
              </li>
              {/* categories.map((category) => (
                <li key={category.id}>
                  <Link to={`/productos/${category.code}`}>
                    {category.name}
                  </Link>
                </li>
              )) */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  inputSelectProduct: '',
};
Navbar.propTypes = {
  cartSize: PropTypes.number.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  closeMenu: PropTypes.func.isRequired,
  isMenuActive: PropTypes.bool.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
  openSearch: PropTypes.func.isRequired,
  closeSearch: PropTypes.func.isRequired,
  isSearchActive: PropTypes.bool.isRequired,
  openSearchModal: PropTypes.func.isRequired,
  products: productsArrayPropTypes.isRequired,
  filterOptions: PropTypes.func.isRequired,
  setInputSelectProduct: PropTypes.func.isRequired,
  productSelected: PropTypes.func.isRequired,
  inputSelectProduct: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Navbar;
