import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import Breadcrumb from 'components/commons/Breadcrumb';
import Benefit from 'components/commons/Benefit';
import Loading from 'components/commons/Loading';
import Input from 'components/commons/Input';
import Captcha from 'components/commons/Captcha';
import Button from 'components/commons/Button';

import './Contact.scss';

const validate = (values) => {
  const errors = {};
  if (!values.first_name) {
    errors.first_name = 'Nombre inválido';
  }
  if (!values.last_name) {
    errors.last_name = 'Apellido inválido';
  }
  /* istanbul ignore next */
  if (!values.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido';
  }
  if (!values.message) {
    errors.message = 'Mensaje inválido';
  }
  if (!values.captcha) {
    errors.captcha = 'Captcha inválido';
  }
  return errors;
};

const Contact = (props) => {
  const {
    handleSubmit, loading, minimumPurchase, onSubmit, submitting,
  } = props;

  const renderContactForm = () => (
    <form className="redux-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <Field
          component={Input}
          formGroupClassName="col-sm-6"
          label="Nombre"
          name="first_name"
        />
        <Field
          component={Input}
          formGroupClassName="col-sm-6"
          label="Apellido"
          name="last_name"
        />
      </div>
      <Field
        component={Input}
        label="Email"
        name="email"
        type="email"
      />
      <Field
        component={Input}
        isTextArea
        label="Mensaje"
        name="message"
      />
      <Field name="captcha" component={Captcha} />
      <Button text="Enviar mensaje" type="submit" buttonClass="btn-block" disabled={submitting} />
    </form>
  );

  const renderLoading = () => (
    <div className="contact-loading">
      <Loading message="Cargando..." />
    </div>
  );

  return (
    <div data-testid="contact-page">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/contacto']} names={['Contacto']} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div id="google-map">
              <div className="map-container">
                <iframe
                  id="map"
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3284.0194102983696!2d-58.4395697855523!3d-34.60367068045954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcca72800dcf95%3A0xbeab8341440412ec!2sCamargo%20136%2C%20C1414AHD%20CABA!5e0!3m2!1ses-419!2sar!4v1604706324192!5m2!1ses-419!2sar"
                  allowFullScreen=""
                  aria-hidden="false"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 get-in-touch-col">
            <div className="contact-contents">
              <h2>Información</h2>
              <div>
                <span>Teléfono: </span>
                <span>1161250017</span>
              </div>
              <div>
                <span>Email: </span>
                <span>
                  <a href="mailto:ventas.craco@gmail.com">
                    ventas.craco@gmail.com
                  </a>
                </span>
              </div>
              <div>
                <span>Dirección: </span>
                <span>
                  Camargo 136
                </span>
              </div>
            </div>
            <div className="get-in-touch-contents">
              <h2>Contáctenos</h2>
              <p>
                Estamos aquí para ayudar y responder cualquier pregunta que pueda tener
              </p>
              {loading && renderLoading()}
              {!loading && renderContactForm()}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

Contact.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'contact',
  validate,
})(Contact);
