import { createSelector } from 'reselect';
import { getProducts } from 'redux/selectors/product.selector';

const getCartItems = (state) => state.cart.items;
const getItem = (state, props) => props.item;

export const itemQuantitySelector = createSelector([getCartItems, getItem],
  (cartItems, item) => (
    cartItems.find((cartItem) => cartItem.itemId === item.id, null)
  ));

export const itemsQuantitySelector = createSelector([getCartItems, getItem],
  (cartItems, item) => (
    cartItems.filter((cartItem) => cartItem.itemId === item.id, null)
  ));

export const cartSizeSelector = createSelector([getCartItems], (items) => (
  items.reduce((a, b) => a + b.quantity, 0)
));

export const cartItemsSelector = createSelector([getProducts, getCartItems],
  (products, cartItems) => {
    const items = [];
    cartItems.forEach((cartItem) => {
      const descriptions = [];
      const item = products.find((product) => product.id === cartItem.itemId, null);
      const description = item ? item.stock_product_descriptions.find((descriptionData) => descriptionData.stock_description_id === cartItem.descriptionId, null) : null;
      if (item) {
        if (description) {
          const itemWithDescription = items.find((product) => product.itemId === cartItem.itemId, null);
          if (itemWithDescription) {
            description.quantity = cartItem.quantity;
            itemWithDescription.descriptions.push({ ...description });
            itemWithDescription.quantity += cartItem.quantity;
          } else {
            description.quantity = cartItem.quantity;
            descriptions.push(description);
            items.push({ ...cartItem, item, descriptions });
          }
        } else {
          items.push({ ...cartItem, item });
        }
      }
    });
    return items;
  });

export const cartSubtotalSelector = createSelector([getProducts, getCartItems],
  (products, cartItems) => {
    const total = cartItems.reduce((a, b) => {
      const cartItem = products.find((product) => product.id === b.itemId, null);
      let price = 0;
      if (cartItem) {
        price = cartItem.sale ? cartItem.sale * b.quantity : cartItem.price * b.quantity;
      }
      return a + price;
    }, 0);
    return total;
  });
