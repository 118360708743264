import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { cartSizeSelector } from 'redux/selectors/cart.selector';
import { connect } from 'react-redux';
import cartImage from './assets/cart.svg';

import './Cart.scss';

const Cart = (props) => {
  const { cartSize } = props;
  return (
    <Link
      className="cartCircle"
      to="/carrito"
    >
      <img src={cartImage} className="cartCircle-image" alt="cart" />
      <span id="checkout-cirlce" className="checkout-cirlce">
        {cartSize}
      </span>

    </Link>
  );
};

const mapStateToProps = (state) => ({
  cartSize: cartSizeSelector(state),
});

Cart.propTypes = {
  cartSize: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(Cart);
