import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';
import Breadcrumb from 'components/commons/Breadcrumb';
import QuantitySelector from 'components/commons/QuantitySelector';
import Button from 'components/commons/Button';
import Benefit from 'components/commons/Benefit';

import './Cart.scss';
import { Collapse, TextField } from '@material-ui/core';
import { getLocalStorage } from 'helpers/storage';

const Cart = (props) => {
  const {
    cartSize, items, minimumPurchase, onQuantityChange, onMessageChange, onRemoveFromCart, subtotal, priceDiscount, onItemClick,
  } = props;
  const showCart = cartSize > 0;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const renderEmptyCart = () => (
    <h2 className="text-center" data-testid="empty-cart">
      El carrito está vacío
    </h2>
  );
  const renderFullCart = () => (
    <div className="cart" data-testid="full-cart">
      <h2 className="text-center">
        {`Hay ${cartSize} producto${cartSize > 1 ? 's' : ''}`}
      </h2>
      <div className="cart-items">
        <div className="separator" />
        {items.map(({
          item, quantity, descriptions, descriptionId, message,
        }) => (
          <div className="cart-item" key={descriptionId ? `cart-item-${item.id}-${descriptionId}` : `cart-item-${item.id}`}>
            <div className="d-flex">
              <Button
                text={<i className="fa fa-remove" />}
                buttonClass="cart-item-remove"
                onClick={() => onRemoveFromCart(item)}
              />
            </div>
            <div className="row">
              <div className="col-md-4 d-flex align-items-center justify-content-center">
                <img onClick={() => onItemClick(item)} src={item.image1} alt={item.name} />
              </div>
              <div className="col-md-8">
                <ul className="cart-item-info">
                  <li className="d-flex flex-row">
                    <span className="cart-item-title">
                      {item.name}
                    </span>
                  </li>
                  <li>
                    <span className="cart-item-description">
                      {item.description}
                    </span>
                  </li>
                  <li>
                    <span className="cart-item-price">
                      {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                      {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                    </span>
                  </li>
                  {descriptions ? (
                    <li className="descriptions-col">
                      {
                    descriptions.map((description) => (
                      <>
                        <div className="float-xs-left" key={`cart-description-${item.id}-${description.stock_description_id}`}>
                          <span className="cart-item-description">
                            { description.stock_descriptions.rgb ? (
                              <div className="description-span">
                                <strong className="span-strong">{description.stock_descriptions.name}</strong>
                                <span className="color-span" style={{ backgroundColor: description.stock_descriptions.rgb }} />
                              </div>
                            ) : (
                              <>
                                {' '}
                                {description.stock_descriptions.name}
                              </>
                            )}
                          </span>
                          <QuantitySelector
                            limit={description.stock}
                            onQuantityChange={(value) => onQuantityChange(item, value, description, message)}
                            quantity={description.quantity}
                            description={description}
                          />
                        </div>
                      </>
                    ))
                    }

                    </li>
                  ) : (
                    <>
                      <li>
                        <QuantitySelector
                          limit={item.stock}
                          onQuantityChange={(value) => onQuantityChange(item, value, null, message)}
                          quantity={quantity}
                          description={null}
                        />
                      </li>
                      <li>
                        <Button
                          onClick={() => { setId(item.id); setOpen(!open); }}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          text="Agregar comentario"
                        />
                        <Collapse in={id === item.id ? open : false}>
                          <div id="example-collapse-text">
                            <TextField
                              label=""
                              value={message}
                              onChange={(value) => onMessageChange(item, quantity, null, value.target.value)}
                              id="margin-none"
                              style={{ width: '100%' }}
                              helperText="Comentario del producto"
                            />
                          </div>
                        </Collapse>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="separator" />
          </div>
        ))}
      </div>
    </div>
  );

  const renderCheckoutDetails = () => (
    <div className="row" data-testid="checkout-details">
      <div className="col">
        <div className="checkout">
          <ul className="checkout-details">
            <li>
              Subtotal:
              {priceDiscount === null ? (
                <span className="float-right">
                  {`$${subtotal.toLocaleString('de-DE')}`}
                </span>
              ) : (
                <del className="float-right">
                  {`$${subtotal.toLocaleString('de-DE')}`}
                </del>
              )}
            </li>
            {priceDiscount != null ? (
              <li>
                Con descuento
                {' '}
                {getLocalStorage('percentage')}
                %:
                <span className="float-right">
                  {`$${priceDiscount.toLocaleString('de-DE')}`}
                </span>
              </li>
            ) : ''}
          </ul>
          <div className="row">
            <div className="col-lg-6">
              <Button text="Envío a domicilio" buttonClass="checkout-button" href="/envio-a-domicilio" />
            </div>
            <div className="col-lg-6">
              <Button text="Retiro en Sucursal Craco" buttonClass="checkout-button" href="/retiro-en-sucursal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div data-testid="page-cart">
      <div className="container">
        <div className="row">
          <div className="col clearfix">
            <Breadcrumb urls={['/carrito']} names={['Carrito']} />
            <div className="row">
              <div className="col">
                {!showCart && renderEmptyCart()}
                {showCart && renderFullCart()}
                {showCart && renderCheckoutDetails()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <Benefit minimumPurchase={minimumPurchase} />
      </div>
    </div>
  );
};

Cart.propTypes = {
  cartSize: PropTypes.number.isRequired,
  items: cartItemsArrayPropTypes.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  subtotal: PropTypes.number.isRequired,
  onItemClick: PropTypes.func.isRequired,

};

export default Cart;
