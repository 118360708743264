import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { productPropTypes } from 'helpers/propTypes';

import QuantitySelector from 'components/commons/QuantitySelector';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Product.scss';
import { getCookie, setCookie } from 'helpers/cookies';
import { setVisitsProducts } from 'services/user.services';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import DescriptionItem from 'components/commons/DescriptionItem';

const Product = (props) => {
  const {
    images, item, onProductClick, onProductCopyClick, onQuantityChange, quantity,
    singleProductFullUrl, cartItemDescriptions,
  } = props;
  useEffect(() => {
    updateCookie();
  });

  const updateCookie = async () => {
    try {
      if (getCookie('visit_product')) {
        const arrCount = getCookie('visit_product');
        addProduct(arrCount);
      } else {
        const arrCount = [];
        addProduct(arrCount);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addProduct = async (arrCount) => {
    const existProduct = arrCount.includes(item.id);
    if (!existProduct) {
      arrCount.push(item.id);
      setCookie('visit_product', arrCount, { maxAge: 86400 });
      const data = {
        id: item.id,
      };
      await setVisitsProducts(data);
    }
  };

  return (
    <div data-testid="product-modal">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="product-details-image-container">
              <Carousel
                autoPlay
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                swipeable={false}
              >
                {images.map((image, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="img-holder">
                    <Zoom>
                      <img alt={item.name} key={`image${index}`} src={image} width="250" />
                    </Zoom>
                  </div>
                ))}
              </Carousel>
            </div>

          </div>
          <div className="col-lg-7">
            <div className="product-details">
              <span
                className="product-details-name"
                onClick={onProductClick}
                role="presentation"
              >
                {item.name}
              </span>
              <CopyToClipboard
                text={singleProductFullUrl}
                onCopy={onProductCopyClick}
              >
                <i
                  className="fa fa-clone product-details-copy-icon"
                  aria-hidden="true"
                  title="Copiar link del producto"
                />
              </CopyToClipboard>
              {item.sale ? <div style={{ color: 'red' }}>{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <div />}
              <div className="product-details-section">
                <div className="product-details-price">
                  {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                  {!!item.sale && <span>{`$${item.price.toLocaleString('de-DE')}`}</span>}
                </div>
              </div>
              <div className="separator" />
              <div className="product-details-section">
                <ul>
                  {
                !!item.stock_product_descriptions.length > 0
                && (
                  item.stock_product_descriptions.map((description, i) => (
                    description.stock > 0 && (
                    <li className="float-xs-left" key={`${item.id}-${description.stock_description_id}`}>
                      <DescriptionItem
                        description={description}
                        onQuantityChange={onQuantityChange}
                        storage={cartItemDescriptions.find((data) => (data.descriptionId === description.stock_description_id))}
                      />
                    </li>
                    )
                  ))
                )
              }
                </ul>
              </div>
              {
                !item.stock_product_descriptions.length > 0
                && (
                  item.stock > 0 ? (
                    <QuantitySelector
                      limit={item.stock}
                      onQuantityChange={onQuantityChange}
                      quantity={quantity}
                    />
                  ) : (
                    <span className="product-details-out-of-stock">Sin stock</span>
                  ))
              }
              <div className="product-details-section">
                <span className="product-details-description">{item.description}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  cartItemDescriptions: PropTypes.array.isRequired,
  item: productPropTypes.isRequired,
  onProductClick: PropTypes.func.isRequired,
  onProductCopyClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  singleProductFullUrl: PropTypes.string.isRequired,
};

export default Product;
