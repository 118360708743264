import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

import { categoriesArrayPropTypes, userPropTypes, productsArrayPropTypes } from 'helpers/propTypes';
import { logout } from 'redux/actions/user.actions';
import { openLoginModal, openRegisterModal, openSearchModal } from 'redux/actions/modal.actions';
import { cartSizeSelector } from 'redux/selectors/cart.selector';
import history from 'helpers/history';

import Menu from 'components/main/Header/Menu';
import TopNav from 'components/main/Header/TopNav';
import Navbar from 'components/main/Header/Navbar';
import Button from 'components/commons/Button';

const HeaderContainer = (props) => {
  const {
    categories, cartSize, logoutAction, openLoginModalAction,
    openRegisterModalAction, openSearchModalAction, user, products,
  } = props;
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const removeAccents = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const [inputSelectProduct, setInputSelectProduct] = useState('');
  // const [selectProduct, setSelectProduct] = useState({});

  const filterOptions = (options, { inputValue }) => {
    let search = inputValue;
    let newOptions = [];
    if (search !== undefined) {
      search = (removeAccents(search));
    }
    if (!search) {
      return newOptions;
    }

    const value = search.trim().toLowerCase();
    // Start the search with 3 valid characters
    if (value.length < 3) {
      return newOptions;
    }

    newOptions = options.filter((product) => {
      const name = (removeAccents(product.name));
      let tags = false;
      if (!!product.tags.length > 0) {
        tags = product.tags.some((element) => element.name.toString().includes(value));
      }
      return name.toLowerCase().indexOf(value) !== -1 || tags;
    });
    return newOptions;
  };

  const onSubmit = () => {
    let search = inputSelectProduct;
    if (search !== undefined) {
      search = (removeAccents(search));
    }
    const value = search.trim().toLowerCase();
    history.push(`/busqueda/${value}`);
  };

  const handleInputChange = (event, value) => {
    if (value.length >= 1) {
      setInputSelectProduct(value);
    }
  };

  const productSelected = (product) => {
    if (product) {
      const singleProductUrl = `/producto/${product.code.replaceAll('%', '')}`;
      history.push(singleProductUrl);
    }
  };

  const renderResponsiveMenu = () => (
    <>
      <div
        className={`fs-menu-overlay${isMenuActive ? ' pointer-active' : ''}`}
        role="presentation"
        onClick={() => setIsMenuActive(false)}
      />
      <div className={`hamburger-menu${isMenuActive ? ' active' : ''}`}>

        <div className="hamburger-menu-content">
          <Menu
            categories={categories}
            closeMenu={() => setIsMenuActive(false)}
            isMobile
            isUserLoggedIn={!!user}
            ulClassName="menu-top-nav"
            logout={logoutAction}
            openLoginModal={openLoginModalAction}
            openRegisterModal={openRegisterModalAction}
            user={user}
          />
        </div>
      </div>
    </>
  );

  const renderResponsiveSearch = () => (
    <>
      <div
        className={`fs-search-menu-overlay${isSearchActive ? ' search-pointer-active' : ''}`}
        role="presentation"
        onClick={() => setIsSearchActive(false)}
      />
      <div className={`search-menu${isSearchActive ? ' active' : ''}`}>

        <div className="search-menu-content">
          <div className="nabvar-searchContainer-mobile">
            <div className="autocomplete">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                classes="nabvar-search"
                options={products}
                filterOptions={filterOptions}
                onChange={(event, product) => {
                  productSelected(product);
                }}
                onInputChange={handleInputChange}
                getOptionLabel={(option) => (option ? option.name : '')}
                renderInput={(params) => <TextField {...params} placeholder="¿Que producto estas buscando?" />}
              />
              <Button
                buttonClass="nabvar-btn-search"
                disabled={false}
                onClick={onSubmit}
                text={<i className="fa fa-search icon-orange" aria-hidden="true" />}
                type="button"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
  return (
    <header className="header trans-300">
      <TopNav
        logout={logoutAction}
        openLoginModal={openLoginModalAction}
        openRegisterModal={openRegisterModalAction}
        user={user}
        filterOptions={filterOptions}
        productSelected={productSelected}
        setInputSelectProduct={setInputSelectProduct}
        inputSelectProduct={inputSelectProduct}
      />
      <div data-testid="navbar">
        <Navbar
          cartSize={cartSize}
          categories={categories}
          closeMenu={() => setIsMenuActive(false)}
          isMenuActive={isMenuActive}
          isUserLoggedIn={!!user}
          openMenu={() => setIsMenuActive(true)}
          openSearch={() => setIsSearchActive(true)}
          closeSearch={() => setIsSearchActive(false)}
          isSearchActive={isSearchActive}
          openSearchModal={openSearchModalAction}
          products={products}
          filterOptions={filterOptions}
          productSelected={productSelected}
          setInputSelectProduct={setInputSelectProduct}
          onSubmit={onSubmit}
          handleInputChange={handleInputChange}
         // selectProduct={selectProduct}
        />
        {renderResponsiveMenu()}
        {renderResponsiveSearch()}
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  cartSize: cartSizeSelector(state),
  categories: state.category.items,
  user: state.user.data,
  products: state.product.items,
});

const mapDispatchToProps = (dispatch) => ({
  logoutAction: bindActionCreators(logout, dispatch),
  openLoginModalAction: bindActionCreators(openLoginModal, dispatch),
  openRegisterModalAction: bindActionCreators(openRegisterModal, dispatch),
  openSearchModalAction: bindActionCreators(openSearchModal, dispatch),
});

HeaderContainer.defaultProps = {
  user: null,
};

HeaderContainer.propTypes = {
  cartSize: PropTypes.number.isRequired,
  categories: categoriesArrayPropTypes.isRequired,
  logoutAction: PropTypes.func.isRequired,
  openLoginModalAction: PropTypes.func.isRequired,
  openRegisterModalAction: PropTypes.func.isRequired,
  openSearchModalAction: PropTypes.func.isRequired,
  user: userPropTypes,
  products: productsArrayPropTypes.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
