import React from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

const Input = (props) => {
  const {
    input, formGroupClassName, inputClassName, isTextArea,
    label, type, meta: { touched, error }, renderRadioDiv, disabled, onKeyPress,
  } = props;
  const InputRef = React.useRef(null);
  React.useEffect(() => {
    if (label === 'Buscar...') {
      InputRef.current.focus();
    }
  }, []);

  const isRadioButton = type === 'radio';
  const isCheckbox = type === 'checkbox';
  const renderInput = () => (
    label === 'Buscar...' ? (
      <input
        {...input}
        placeholder={label}
        type={type}
        className={`form-control ${inputClassName}`}
        data-testid="input"
        ref={InputRef}
        disabled={disabled}
        onKeyPress={onKeyPress}
      />
    ) : (
      <input
        {...input}
        disabled={disabled}
        placeholder={label}
        type={type}
        className={`form-control ${inputClassName}`}
        data-testid="input"
        onKeyPress={onKeyPress}
      />
    )
  );

  if (isRadioButton) {
    return (
      <div className={`form-group ${formGroupClassName}`}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="radio-container">
          {renderInput()}
          {renderRadioDiv()}
        </label>
        {touched && (error && <span className="help-block">{error}</span>)}
      </div>
    );
  }

  if (isCheckbox) {
    return (
      <div className={`form-group ${formGroupClassName}`}>
        <div className="checkbox-container">
          {renderInput()}
          <span>{label}</span>
        </div>
        {touched && (error && <span className="help-block">{error}</span>)}
      </div>
    );
  }

  return (
    <div className={`form-group ${formGroupClassName}`}>
      {!isTextArea && renderInput()}
      {isTextArea && (
        <textarea
          {...input}
          placeholder={label}
          type={type}
          className={`form-control text-area ${inputClassName}`}
          data-testid="input"
        />
      )}
      {touched && (error && <span className="help-block">{error}</span>)}
    </div>
  );
};

Input.defaultProps = {
  formGroupClassName: '',
  inputClassName: '',
  isTextArea: false,
  renderRadioDiv: () => {},
  type: 'text',
  disabled: false,
  onKeyPress: () => {},
};

Input.propTypes = {
  formGroupClassName: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  inputClassName: PropTypes.string,
  isTextArea: PropTypes.bool,
  label: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  renderRadioDiv: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.func,
};

export default Input;
