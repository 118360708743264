/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { productPropTypes } from 'helpers/propTypes';
import QuantitySelector from 'components/commons/QuantitySelector';
import './Item.scss';
import { Grid } from '@material-ui/core';
import DescriptionItem from '../DescriptionItem';
import heartImage from './assets/heart.svg';

const Item = (props) => {
  const {
    item, onItemClick, onQuantityChange, quantity, cartItemDescriptions,
  } = props;

  return (
    <div className="product-item" data-testid={`product-${item.id}`}>
      <div className="product">
        <div className="product-image" onClick={onItemClick} role="presentation">
          <div className="product-image-icons">
            <img src={item.image1} alt={item.name} className="show-filter-categorys" />
            {item.sale ? <div className="price-off">{` ${Math.round(100 - (item.sale / item.price) * 100)}% OFF `}</div> : <></>}
            <div className="favorito-heart">
              <img src={heartImage} className="image-heart-favourite" alt="" />
            </div>
          </div>
          {/* <Grid container spacing={2} className="show-filter-categorys-secondary">
            <Grid item xs={8}>
              <img src={item.image1} alt={item.name} />
            </Grid>
            <div>
              {item.name}
              <div className="product-price">
                <br />
                {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                {!!item.sale && <span data-testid="product-on-sale">{`$${item.price.toLocaleString('de-DE')}`}</span>}
              </div>
              {!item.stock_product_descriptions.length > 0
                ? (item.stock > 0 ? (
                  <div className="product-quantity-selector">
                    <QuantitySelector
                      limit={item.stock}
                      onQuantityChange={onQuantityChange}
                      quantity={quantity}
                    />
                  </div>
                ) : (
                  <span className="product-out-of-stock">Sin stock</span>
                )) : (
                  <div className="product-quantity-selector">
                    {
                !!item.stock_product_descriptions.length > 0
                && (
                  item.stock_product_descriptions.map((description, i) => (
                    description.stock > 0 && (
                    <li className="float-xs-left" key={`${item.id}-${description.stock_description_id}`}>
                      <DescriptionItem
                        description={description}
                        onQuantityChange={onQuantityChange}
                        storage={cartItemDescriptions.find((data) => (data.descriptionId === description.stock_description_id))}
                      />
                    </li>
                    )
                  ))
                )
                }
                  </div>
                )}
            </div>
          </Grid>
              */}
        </div>
        <div className="product-info  show-filter-categorys">
          <h6 className="product-name" onClick={onItemClick} role="presentation">
            <div className="product-title-name">
              {item.name}
            </div>
            {
              item.stock_product_descriptions.length > 0
              && (
              <div className="product-price">
                {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                {!!item.sale && <span data-testid="product-on-sale">{`$${item.price.toLocaleString('de-DE')}`}</span>}
              </div>
              )
            }
            {!item.stock_product_descriptions.length > 0
              && (
              <div className="product-price">
                {`$${item.sale ? item.sale.toLocaleString('de-DE') : item.price.toLocaleString('de-DE')}`}
                {!!item.sale && <span data-testid="product-on-sale">{`$${item.price.toLocaleString('de-DE')}`}</span>}
              </div>
              )}
            {!item.stock_product_descriptions.length > 0
              ? (
                item.stock > 0 ? (
                  <div className="product-quantity-selector">
                    <QuantitySelector
                      limit={item.stock}
                      onQuantityChange={onQuantityChange}
                      quantity={quantity}
                    />
                  </div>
                ) : (
                  <span className="product-out-of-stock">Sin stock</span>
                )) : (
                  <div className="product-quantity-selector">
                    {
                    !!item.stock_product_descriptions.length > 0
                    && (
                    <button type="button" onClick={onItemClick}> Ver colores </button>
                    )
                  }
                  </div>
              )}
          </h6>
        </div>
      </div>
    </div>
  );
};

Item.propTypes = {
  item: productPropTypes.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onQuantityChange: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
  cartItemDescriptions: PropTypes.array.isRequired,
};

export default Item;
