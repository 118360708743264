import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import store from 'redux/store';
import { APP_INITIALIZE_REQUESTED } from 'redux/constants/app.constants';
import history from 'helpers/history';
import Preloader from 'components/commons/Preloader';
import ScrollToTop from 'containers/ScrollToTop';
import HeaderContainer from 'containers/HeaderContainer';
import NavigationContainer from 'containers/NavigationContainer';
import ModalManager from 'containers/ModalManager';
import Whatsapp from 'components/commons/Whatsapp';
import Cart from 'components/commons/Cart';
import Footer from 'components/main/Footer';
import 'App.scss';
import 'react-notifications/lib/notifications.css';
import { getCookie, setCookie } from 'helpers/cookies';
import { setVisits } from 'services/user.services';

const App = () => {
  useEffect(() => {
    updateCookie();
    store.dispatch({ type: APP_INITIALIZE_REQUESTED });
  }, []);

  const updateCookie = async () => {
    try {
      let date = new Date();
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      date = `${year}-${month}-${day}`;
      // console.log(getCookie('visit'))
      // removeCookie("visit")
      if (getCookie('visit') !== date) {
        setCookie('visit', date, { maxAge: 86400 });
        const data = {
          date,
        };
        await setVisits(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Provider store={store}>
      <Router history={history}>
        <div className="super-container">
          <Preloader />
          <ScrollToTop />
          <NotificationContainer />
          <HeaderContainer />
          <div className="probando">
            <NavigationContainer />
            <ModalManager />
            <Cart />
            <Whatsapp />
            <Footer />
          </div>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
