import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import history from 'helpers/history';
import { productPropTypes } from 'helpers/propTypes';
import { getFullUrl } from 'helpers/window';
import { sendSuccessNotification } from 'helpers/notifications';
import { itemQuantitySelector, itemsQuantitySelector } from 'redux/selectors/cart.selector';
import { productImagesSelector } from 'redux/selectors/product.selector';
import { updateCart } from 'redux/actions/cart.actions';
import Product from 'views/Product';

const ProductContainer = (props) => {
  const {
    cartItem, images, item, onClose, updateCartAction, cartItemDescriptions,
  } = props;
  // removeLocalStorage('descriptionsCart')
  const quantity = cartItem ? cartItem.quantity : 0;
  const singleProductUrl = `/producto/${item.code.replaceAll('%', '')}`;
  const singleProductFullUrl = getFullUrl(singleProductUrl);

  const onProductClick = () => {
    onClose();
    history.push(singleProductUrl);
  };

  const onProductCopyClick = () => {
    sendSuccessNotification('Link del producto copiado con éxito');
  };

  const onQuantityChange = (value, description) => {
    if (description) {
      if (value >= 0 && value <= description.stock) {
        updateCartAction(item.id, value, description.stock_description_id, item.message);
      }
    } else if (value >= 0 && value <= item.stock) {
      updateCartAction(item.id, value, null, item.message);
    }
  };

  return (
    <Product
      images={images}
      item={item}
      onProductClick={onProductClick}
      onProductCopyClick={onProductCopyClick}
      onQuantityChange={onQuantityChange}
      quantity={quantity}
      cartItemDescriptions={cartItemDescriptions}
      singleProductFullUrl={singleProductFullUrl}
    />
  );
};

const mapStateToProps = (state, props) => ({
  cartItem: itemQuantitySelector(state, props),
  images: productImagesSelector(state, props),
  cartItemDescriptions: itemsQuantitySelector(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

ProductContainer.defaultProps = {
  cartItem: null,
  cartItemDescriptions: null,
  onClose: () => { },
};

ProductContainer.propTypes = {
  cartItemDescriptions: PropTypes.array.isRequired,
  cartItem: PropTypes.shape({
    itemId: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }),
  item: productPropTypes.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
  updateCartAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
